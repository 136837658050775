<template>
  <div>
    <md-card>
      <md-card-header :data-background-color="getTheme">
        <h4 class="title">
          {{ $t("template.templateDetail.informationTitle") }}
        </h4>
      </md-card-header>

      <md-card-content>
        <div class="md-layout">
          <div
            v-if="template"
            class="md-layout-item md-small-size-100 md-size-100"
          >
            <p>{{ $t("template.templateDetail.name") }}: {{ template.name }}</p>
            <p>
              {{ $t("template.templateDetail.description") }}:
              {{ template.description }}
            </p>
          </div>
        </div>
      </md-card-content>
    </md-card>
    <md-card v-if="!selectedTemplate">
      <md-card-header :data-background-color="getTheme">
        <h4 class="title">{{ $t("template.templateDetail.slotsTitle") }}</h4>
      </md-card-header>

      <md-card-content>
        <md-table
          v-if="template"
          v-model="template.slots"
          :table-header-color="getTheme"
        >
          <md-table-row slot="md-table-row" slot-scope="{ item }">
            <md-table-cell md-label="Alias">{{ item.alias }}</md-table-cell>
            <md-table-cell md-label="Nombre de parámetro">{{
              item.parameter_name
            }}</md-table-cell>
          </md-table-row>
        </md-table>
      </md-card-content>
    </md-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      template: null,
    };
  },
  props: {
    selectedTemplate: {
      type: Number,
      required: false,
    },
  },
  computed: {
    ...mapGetters(["getTheme"]),
  },
  watch: {
    selectedTemplate() {
      this.getTemplate();
    },
  },
  methods: {
    getTemplate() {
      if (this.selectedTemplate) {
        this.$store
          .dispatch("template/getTemplate", this.selectedTemplate)
          .then((data) => {
            this.template = data;
          })
          .catch((err) => console.error(err));
      } else {
        this.$store
          .dispatch("template/getTemplate", this.$route.params.template)
          .then((data) => {
            this.template = data;
          })
          .catch((err) => console.error(err));
      }
    },
  },
  mounted() {
    this.getTemplate();
  },
};
</script>
