<template>
  <div class="container-fluid">
    <div class="row justify-space-between mr-5">
      <tgl-breadcrumbs :items="breadcrumbs" />
      <template-actions
        v-if="$store.getters.getPermission('templates_remove')"
      ></template-actions>
    </div>
    <div class="container-fluid">
      <v-tabs v-model="tab">
        <v-tab @click="updateRoute('details')">{{
          $t("template.templateTab.detail")
        }}</v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab" class="mt-2 rounded-md">
        <v-tab-item>
          <template-details></template-details>
        </v-tab-item>
      </v-tabs-items>
    </div>
  </div>
</template>

<script>
import TemplateDetails from "@/components/Templates/TemplateDetails.vue";
import TglBreadcrumbs from "@/components/UI/TglBreadcrumbs";
import TemplateActions from "@/components/Templates/Actions";

export default {
  components: {
    TemplateDetails,
    TglBreadcrumbs,
    TemplateActions,
  },
  data() {
    return {
      tab: null,
    };
  },
  computed: {
    breadcrumbs() {
      return [
        {
          label: "Plantillas",
          disabled: false,
          to: { name: "templates" },
        },
        {
          label: "Plantilla",
          disabled: true,
          to: { name: "templates" },
        },
      ];
    },
  },
  methods: {
    setTab(tabName) {
      switch (tabName) {
        case "details":
          this.tab = 0;
          break;
        default:
          this.tab = 0;
          break;
      }
    },
    updateRoute(path) {
      this.$router.replace(`/template/${this.$route.params.template}/${path}`);
    },
  },
  mounted() {
    this.setTab(this.$route.params.tab);
  },
};
</script>