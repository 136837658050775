<template>
  <div class="entity-actions">
    <tgl-delete-button
      dispatch="template/deleteTemplate"
      :redirect="{
        name: 'templates',
      }"
      entity="template"
    />
  </div>
</template>

<script>
import TglDeleteButton from "@/components/UI/TglDeleteButton";
export default {
  components: {
    TglDeleteButton,
  },
};
</script>